<template>
    <el-dialog title="修改个人信息" v-model="edit_info" @closed="close_edit_info">
        <el-form :model="base_info" style="margin: 20px;">
            <div class="step_one_content">
                <el-form-item label="姓名" label-width="100px">
                    <el-input v-model="base_info.name" style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="高考年份" label-width="100px">
                    <el-select v-model="base_info.grade" placeholder="高考年份">
                        <el-option label="2023" value="2023"></el-option>
                        <el-option label="2024" value="2024"></el-option>
                        <el-option label="2025" value="2025"></el-option>
                        <el-option label="2026" value="2026"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="省份" label-width="100px">
                    <el-select v-model="base_info.province_id" placeholder="所在省份">
                        <el-option label="请选择" value="0"></el-option>
                        <el-option label="河北" value="13"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选科" label-width="100px">
                    <div>
                        <el-radio v-model="base_info.subject_three" @change="on_change" label="1">物理</el-radio>
                        <el-radio v-model="base_info.subject_three" @change="on_change" label="2">历史</el-radio>
                    </div>
                    <el-checkbox-group
                        v-model="base_info.second"
                        :max="2">
                        <el-checkbox v-for="major in majors" :label="major.index" :key="major.index">
                            {{ major.name }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="分数" label-width="100px">
                    <el-input-number v-model="base_info.score" :min="100" :max="750"
                                     style="width: 300px;"></el-input-number>
                </el-form-item>
            </div>
        </el-form>
        <template #footer>
    <span class="dialog-footer">
      <el-button @click="cannel_submit">取 消</el-button>
      <el-button type="primary" @click="submit_info">确 定</el-button>
    </span>
        </template>
    </el-dialog>
</template>

<script>
import api from '@/api'
import axios from "axios";
import {ElMessageBox, ElMessage} from 'element-plus'

export default {
    name: 'EditInfo',
    props: {
        show: Number
    },
    data() {
        return {
            edit_info: false,
            base_info: {
                'class_id': 0,
                'grade': '2023',
                'score': 500,
                'classes': 0,
                'name': '',
                'province': '0',
                'province_id': '0',
                'subject_one': 0,
                'subject_three': 0,
                'subject_two': 0,
                'subjects': "",
                'majors': [],
                'second': [],
            },
            majors: [
                {'name': '化学', 'index': '3'},
                {'name': '生物', 'index': '4'},
                {'name': '思想政治', 'index': '5'},
                {'name': '地理', 'index': '6'},
            ],
            change: 0,
            show_remind: true,
            subject_three: 0,
        }
    },
    watch: {
        show(new_val) {
            this.edit_info = new_val > 0;
        }
    },
    mounted() {
        this.base_info = JSON.parse(localStorage.getItem('base_info'))
        this.base_info.score = parseInt(this.base_info.score)
        this.base_info.grade = '2023'
        if(this.base_info.classes){
            this.base_info.grade = this.base_info.classes + ''
        }
        console.log(this.base_info)

        this.base_info.second = []
        if (this.base_info.subject_one !== null && this.base_info.subject_one.length > 0 && parseInt(this.base_info.subject_one) !== 0) {
            this.base_info.second.push(this.base_info.subject_one)
        }
        if (this.base_info.subject_two !== null && this.base_info.subject_two.length > 0 && parseInt(this.base_info.subject_two) !== 0) {
            this.base_info.second.push(this.base_info.subject_two)
        }

        this.edit_info = this.show > 0

        console.log(this.base_info.subject_three)
        if (this.base_info.subject_three === '0' || this.base_info.subject_three === 0 || this.base_info.subject_three === '') {
            this.show_remind = false
        } else {
            this.subject_three = parseInt(this.base_info.subject_three)
        }
    },
    created() {
    },
    methods: {
        cannel_submit() {
            this.edit_info = false
            this.base_info = JSON.parse(localStorage.getItem('base_info'))
            this.base_info.second = []
            if (this.base_info.subject_one !== null && this.base_info.subject_one.length > 0 && parseInt(this.base_info.subject_one) !== 0) {
                this.base_info.second.push(this.base_info.subject_one)
            }
            if (this.base_info.subject_two !== null && this.base_info.subject_two.length > 0 && parseInt(this.base_info.subject_two) !== 0) {
                this.base_info.second.push(this.base_info.subject_two)
            }

            if (this.base_info.subject_three === '0' || this.base_info.subject_three === 0) {
                this.show_remind = false
            }
        },
        on_change(e) {
            let that = this
            console.log(e)
            if (this.show_remind === false) {
                return false
            }
            if (this.subject_three === parseInt(that.base_info.subject_three)) {
                return false
            }
            console.log(that.base_info.subject_three)
            ElMessageBox.confirm(
                '您修改了首选科目，此操作会清空志愿选择列表！',
                '警告',
                {
                    confirmButtonText: '放弃',
                    cancelButtonText: '继续修改',
                    type: 'warning',
                }
            ).then(() => {
                if (that.base_info.subject_three === '1') {
                    that.base_info.subject_three = '2'
                } else {
                    that.base_info.subject_three = '1'
                }
            }).catch(() => {
                that.change = 1
            })
        },
        close_edit_info(e) {
            this.edit_info = false
        },
        submit_info() {
            let that = this

            for (let i in this.base_info.second) {
                this.base_info.majors.push(this.base_info.second[i])
            }
            this.base_info.majors.push(this.base_info.subject_three)
            this.base_info.subject_one = this.base_info.second[0]
            this.base_info.subject_two = this.base_info.second[1]
            this.base_info.change = this.change
            this.base_info.classes = parseInt(this.base_info.grade)

            if (this.base_info.second.length !== 2) {
                that.$notify.error({
                    title: '错误',
                    message: '请选择两个再选科目',
                    offset: 50,
                });
                return false
            }
            if (this.base_info.name.length < 1) {
                that.$notify.error({
                    title: '错误',
                    message: '请填写姓名',
                    offset: 50,
                });
                return false
            }
            if (parseInt(this.base_info.subject_three) !== 1 && parseInt(this.base_info.subject_three) !== 2) {
                that.$notify.error({
                    title: '错误',
                    message: '请选择首选科目',
                    offset: 50,
                });
                return false
            }
            if (parseInt(this.base_info.province_id) < 1) {
                that.$notify.error({
                    title: '错误',
                    message: '请选择省份',
                    offset: 50,
                });
                return false
            }


            let old_info = JSON.parse(localStorage.getItem('base_info'))

            if((old_info.score == '' || old_info.score == 0) && (old_info.subject_one == '' || old_info.subject_one == 0)){
                this.change_info(this.base_info)
            }else if (this.base_info.score != old_info.score || this.base_info.subject_one != old_info.subject_one || this.base_info.subject_two != old_info.subject_two || this.base_info.subject_three != old_info.subject_three) {
                ElMessageBox.confirm(
                    "修改选科或分数信息会清空志愿收藏列表！",
                    '警告',
                    {
                        confirmButtonText: '放弃',
                        cancelButtonText: '继续修改',
                        type: 'warning',
                    }
                ).then(() => {
                }).catch(() => {
                    that.change_info(this.base_info)
                })
            } else {
                this.change_info(this.base_info)
            }
        },

        change_info(base_info) {
            this.edit_info = false
            let that = this
            axios.post(
                api.user.input, base_info
            ).then(function (res) {
                if (res.data.data.code === 10000) {
                    let base_info = JSON.parse(localStorage.getItem('base_info'))

                    base_info.second = [res.data.data.base_info.subject_one, res.data.data.base_info.subject_two]
                    base_info.subject_one = res.data.data.base_info.subject_one
                    base_info.subject_two = res.data.data.base_info.subject_two
                    base_info.subject_three = res.data.data.base_info.subject_three
                    base_info.subjects = res.data.data.base_info.subjects
                    base_info.score = res.data.data.base_info.score
                    base_info.province = res.data.data.base_info.province
                    base_info.province_id = res.data.data.base_info.province_id
                    base_info.name = res.data.data.base_info.name

                    localStorage.setItem('base_info', JSON.stringify(base_info))
                    localStorage.setItem('branch', JSON.stringify(res.data.data.branch))
                    that.branch = res.data.data.branch
                    that.active = 2
                    that.$notify({
                        title: '成功',
                        message: '信息录入成功',
                        type: 'success',
                        offset: 50,
                    });
                    that.$emit('fatherMethod');
                    //that.$router.go(0);
                } else {
                    that.$notify.error({
                        title: '错误',
                        message: res.data.data.msg,
                        offset: 50,
                    });
                }
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}
</style>
