module.exports = {
    development: {
        MODE: 'development',
        TOKEN: '',
        API: 'https://api-zhiyuan.kboy.fun/website/v1/',
        // API: 'https://ceshi.shudaozaixian.com/website/v1/',
        VOCATION_API: 'https://api-vocation.kboy.fun/website/v1/',
        PRE_API: 'https://api-pre.kboy.fun/website/v1/',
        ART_API: 'https://arts.shudaozaixian.com/website/v1/',
        JSON_SITE: 'https://api-zhiyuan.kboy.fun/',
        // REDIRECT_URL: 'http%3A%2F%2F192.168.1.6%3A8080%2Flogin',
        REDIRECT_URL: 'http%3A%2F%2Flocalhost%3A8080%2Flogin',
    },
    test: {
        MODE: 'test',
        TOKEN: '',
        HOME: '',
        API: ''
    },
    production: {
        MODE: 'production',
        TOKEN: '',
        HOME: '',
        API: 'https://ceshi.shudaozaixian.com/website/v1/',
        VOCATION_API: 'https://vocational.shudaozaixian.com/website/v1/',
        PRE_API: 'https://preb.shudaozaixian.com/website/v1/',
        ART_API: 'https://arts.shudaozaixian.com/website/v1/',
        JSON_SITE: 'https://ceshi.shudaozaixian.com/',
        REDIRECT_URL: 'http%3A%2F%2Fzhiyuan.shudaozaixian.com%2Flogin',
    }
}