import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
import 'element-plus/lib/theme-chalk/index.css'
import Particles from "particles.vue3"
import * as icons from '@element-plus/icons';
import Print from 'vue3-print-nb'

// 设置标题
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})


const app = createApp(App)
Object.keys(icons).forEach(key => {
    app.component(key, icons[key])
})
app.use(Particles).use(Print).use(store).use(ElementPlus).use(router).mount('#app')
