export default {
    school: {
        area: 'school/area',
        brief_list: 'school/brief_list',
        type: 'school/type',
        level: 'school/level',
        list: 'school/list',
        label: 'school/label',
        list_json: 'school/list.json',
        school_label_json: 'school_label/list.json',
        extra:'school/extra',
        enroll_info:'school/enroll_info',
        enroll_list:'school/enroll_list',
        first_class:'school/first_class',
        special_major:'school/special_major',
        subject_review:'school/subject_review',
        department:'school/department',
        jobs_report:'school/jobs_report',
        plans:'school/plans',
        scores:'school/scores',
        base_info:'school/base_info',
    },
    province: {
        list_json: 'province/list.json',
    },
    major: {
        list: 'major/list',
        search: 'major/search',
        list_json: 'majors/list.json',
    },
    open_wx: {
        access_token: 'open-wx/access-token',
    },
    personality: {
        save_code: 'personality/save_code',
        hld: 'personality/hld',
        hld_desc: 'personality/hld_desc',
        major_hld: 'personality/major_hld',
        major_direction: 'personality/major_direction',
    },
    user: {
        login: 'user/login',
        get_card_num: 'user/get_card_num',
        upload_collection: 'user/upload_collection',
        power: 'user/power',
        optional_major_analysis: 'user/optional_major_analysis',
        need_login: 'user/need_login',
        get_report: 'user/get_report',
        get_report_test: 'user/get_report_test',
        scan_status: 'user/scan_status',
        change_password: 'user/change_password',
        register: 'user/register',
        input: 'user/input',
        major_analysis: 'user/major_analysis',
        extra_info: 'user/extra_info',
        areas: 'user/areas',
        favorite: 'user/favorite',
        un_favorite: 'user/un_favorite',
        favorites: 'user/favorites',
        send_code: 'user/send_code',
        search_param: 'user/search_param',
        collection_school: 'user/collection_school',
        member_collection: 'user/member_collection',
        approval_info: 'user/approval_info',
        approval_list: 'user/approval_list',
        send_2_instructor: 'user/send_2_instructor',
        binding_approval: 'user/binding_approval',
        collect_sort_change: 'user/collect_sort_change',
        get_phone_code: 'user/get_phone_code',
        send_phone_code: 'user/send_phone_code',
        info: 'user/info',
        report_list: 'user/report_list',
        open_experience_vip: 'user/open_experience_vip',
        remove_report: 'user/remove_report',
        report_info: 'user/report_info',
        login_qr_code: 'user/login_qr_code',
        get_agent: 'user/get_agent',
        get_agreement: 'user/get_agreement',
        recommend_rank: 'user/recommend_rank',
        favorites_class: 'user/favorites_class',
        personality_test: 'user/personality_test',
    },
    system: {
        status: 'system/status',
        config: 'system/config',
    },
    member: {
        collections: 'member/collections',
        collect: 'member/collect',
    },
    system_tips: {
        list: 'system_tips/list',
    },
    open_major: {
        majors_info: 'open_major/majors_info',
        list: 'open_major/list',
        major_info: 'open_major/major_info',
        school_info: 'open_major/school_info',
    },
    junior_major: {
        list: 'junior_major/list',
        major_info: 'junior_major/major_info',
    },
    favorite: {
        change: 'favorite/change',
        download: 'favorite/download',
    },
    search_param: {
        province: 'search_param/province',
        school_list: 'search_param/school_list',
        majors: 'search_param/majors',
        school_label: 'search_param/school_label',
    },
    match: {
        list: 'match/list',
        majors: 'match/majors',
    },
    goods: {
        list: 'goods/list',
        order: 'goods/order',
        pay_status: 'goods/pay_status',
        check_coupon: 'goods/check_coupon',
    },
    algorithm: {
        collect: 'algorithm/collect'
    },
    instructor: {
        pending_approval: 'instructor/pending_approval',
        student_info: 'instructor/student_info',
        assess_plan: 'instructor/assess_plan',
        send_2_member: 'instructor/send_2_member',
        update_summary: 'instructor/update_summary',
        review: 'instructor/review',
    }
}