import {createRouter, createWebHistory} from 'vue-router'
import ChoseCollege from '../views/ChoseCollege'

const routes = [
    {
        path: '/',
        name: 'Admin',
        redirect: '/login',
        component: ChoseCollege,
        meta: {
            title: '选志愿'
        },
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue'),
        meta: {
            title: '注册账户'
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
        meta: {
            title: '登录系统后台'
        },
    },
    {
        path: '/assess_info',
        name: 'Assess_Info',
        component: () => import(/* webpackChunkName: "about" */ '../views/Assess_Info.vue'),
        meta: {
            title: '测评选专业'
        },
    },
    {
        path: '/subject_analysis',
        name: 'SubjectAnalysis',
        component: () => import(/* webpackChunkName: "about" */ '../views/SubjectAnalysis.vue'),
        meta: {
            title: '辅助选科'
        },
    },
    {
        path: '/chose_ahead',
        name: 'ChoseAhead',
        component: () => import(/* webpackChunkName: "about" */ '../views/ChoseAhead.vue'),
        meta: {
            title: '提前批'
        },
    },
    // {
    //     path: '/open_login',
    //     name: 'Open_Login',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/WXLogin.vue'),
    //     meta: {
    //         title: '登录系统后台'
    //     },
    // },
    {
        path: '/chose_college',
        name: 'ChoseCollege',
        component: ChoseCollege,
        meta: {
            title: '选志愿'
        },
    },
    {
        path: '/chose_school',
        name: 'ChoseSchool',
        component: () => import(/* webpackChunkName: "about" */ '../views/ChoseSchool.vue'),
        meta: {
            title: '本科普通批'
        },
    },
    {
        path: '/schools',
        name: 'Schools',
        component: () => import(/* webpackChunkName: "about" */ '../views/Schools.vue'),
        meta: {
            title: '查大学'
        },
    },
    {
        path: '/goods',
        name: 'Goods',
        component: () => import(/* webpackChunkName: "about" */ '../views/Goods.vue'),
        meta: {
            title: '开通VIP'
        },
    },
    {
        path: '/majors',
        name: 'Majors',
        component: () => import(/* webpackChunkName: "about" */ '../views/Majors.vue'),
        meta: {
            title: '查专业'
        },
    },
    {
        path: '/chose_school_plan',
        name: 'ChoseSchoolPlan',
        component: () => import(/* webpackChunkName: "about" */ '../views/ChoseSchoolPlan.vue'),
        meta: {
            title: '本科普通批'
        },
    },
    {
        path: '/chose_art',
        name: 'ChoseArt',
        component: () => import(/* webpackChunkName: "about" */ '../views/ChoseArt.vue'),
        meta: {
            title: '体育-艺术类'
        },
    },
    {
        path: '/school_info',
        name: 'SchoolInfo',
        component: () => import(/* webpackChunkName: "about" */ '../views/SchoolInfo.vue'),
        meta: {
            title: '学校详情'
        },
    },
    {
        path: '/school_map',
        name: 'SchoolMap',
        component: () => import(/* webpackChunkName: "about" */ '../views/SchoolMap.vue'),
        meta: {
            title: '地理位置'
        },
    },
    {
        path: '/chose_vocation',
        name: 'ChoseVocation',
        component: () => import(/* webpackChunkName: "about" */ '../views/ChoseVocation.vue'),
        meta: {
            title: '专科批'
        },
    },
    // {
    //     path: '/favorite_list',
    //     name: 'FavoriteList',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/FavoriteList.vue'),
    //     meta: {
    //         title: '专家列表'
    //     },
    // },
    {
        path: '/home_page',
        name: 'HomePage',
        component: () => import(/* webpackChunkName: "about" */ '../views/HomePage.vue'),
        meta: {
            title: '志愿填报'
        },
    },
    // {
    //     path: '/goods',
    //     name: 'Goods',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/Goods.vue'),
    //     meta: {
    //         title: '商品列表'
    //     },
    // },
    {
        path: '/approval',
        name: 'Approval',
        component: () => import(/* webpackChunkName: "about" */ '../views/Approval.vue'),
        meta: {
            title: '审核志愿'
        },
    },
    {
        path: '/report',
        name: 'Report',
        component: () => import(/* webpackChunkName: "about" */ '../views/Report.vue'),
        meta: {
            title: '我的志愿报告'
        },
    },
    {
        path: '/index',
        name: 'Index',
        component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue'),
        meta: {
            title: '首页'
        },
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
