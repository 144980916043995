import axios from "axios"
import env from './env'
import QS from 'qs'

axios.defaults.baseURL = env[process.env.NODE_ENV].API
// 设置超时
// axios.defaults.timeout = 10000

axios.interceptors.request.use(function (config) {
    // post请求头
    let token = localStorage.getItem("jwt")
    //config.headers['Content-Type'] = 'text/html;charset=utf8'
    if (token) {
        config.headers.Authorization = token;
    }
    return config;
}, function (err) {
    return Promise.reject(err);
})

export default {
    post(url, params = {}) {
        return new Promise((resolve, reject) => {
            axios.post(url, QS.stringify(params))
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    get(url, params = {}) {
        return new Promise((resolve, reject) => {
            axios.get(url, {
                params: params
            }).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err.data)
            })
        })
    }
};