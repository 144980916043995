<template>
    <header class="header">
        <div class="header_info">
            <div class="div_logo">
                <img class="img_logo" v-if="logo.length > 1" style="width: 40px;height: 40px;" :src="logo" alt=""/>
                <span class="name_logo">{{ site_name }}</span>
            </div>
            <!--                        <div class="div_nav" v-if="activeIndex > 0">-->
            <div :class="activeIndex===2?'selected_div':'no_selected_div'">
                <router-link :class="activeIndex===2?'selected':'no_selected'" to="#" @click="enter_home_page">
                    个人中心
                </router-link>
            </div>
            <div :class="activeIndex===10?'selected_div':'no_selected_div'">
                <router-link :class="activeIndex===10?'selected':'no_selected'" to="/schools">
                    查大学
                </router-link>
            </div>
            <div :class="activeIndex===11?'selected_div':'no_selected_div'">
                <router-link :class="activeIndex===11?'selected':'no_selected'" to="/majors">
                    查专业
                </router-link>
            </div>
            <div :class="activeIndex===7?'selected_div':'no_selected_div'">
                <router-link :class="activeIndex===7?'selected':'no_selected'" to="/subject_analysis">
                    辅助选科
                </router-link>
            </div>
            <div :class="activeIndex===8?'selected_div':'no_selected_div'">
                <router-link :class="activeIndex===8?'selected':'no_selected'" to="/assess_info">
                    测评选专业
                </router-link>
            </div>
            <!--            <div-->
            <!--                style="color: white;font-size: 15px;min-width: 150px;text-align: center;line-height: 40px;height: 40px;cursor: pointer;">-->
            <!--                <router-link :class="activeIndex===9?'selected':'no_selected'" to="/chose_school_plan">-->
            <!--                    本科普通批-->
            <!--                </router-link>-->
            <!--            </div>-->
            <div :class="activeIndex===4?'selected_div':'no_selected_div'">
                <el-dropdown size="large" @click="menu_click" @command='menu_command'>
                        <span :class="activeIndex===4?'selected':'no_selected'">
                          选志愿
                          <el-icon class="el-icon--right">
                            <arrow-down/>
                          </el-icon>
                        </span>
                    <template #dropdown>
                        <el-dropdown-menu>
<!--                            <el-dropdown-item command='a'>提前批A</el-dropdown-item>-->
                            <el-dropdown-item command='b'>提前批B</el-dropdown-item>
                            <el-dropdown-item command='ben'>本科普通批</el-dropdown-item>
                            <el-dropdown-item command='z'>专科批</el-dropdown-item>
                            <!--                            <el-dropdown-item command='art'>体育-艺术类</el-dropdown-item>-->
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
            <div :class="activeIndex===5?'selected_div':'no_selected_div'">
                <router-link :class="activeIndex===5?'selected':'no_selected'" to="#" @click="sign_out">
                    退出登录
                </router-link>
            </div>
            <!--                        </div>-->
        </div>
    </header>
</template>

<script>
import logo from '@/assets/logo.png';

export default {
    name: 'Header',
    props: {
        chose_index: String,
        send_collection: {
            type: Function,
            default: null
        },
        father_send_collection: {
            type: Function,
            default: null
        }
    },
    data() {
        return {
            activeIndex: '1',
            logo: logo,
            approval_num: 0,
            site_name: '茗硕',
        }
    },
    created() {

        let base_info = JSON.parse(localStorage.getItem('base_info'))
        if (base_info !== null) {
            if (base_info.site_name !== undefined && base_info.site_name.length > 1) {
                this.site_name = base_info.site_name
            }
            if (base_info.site_logo !== undefined && base_info.site_logo.length > 1) {
                this.logo = base_info.site_logo
            }

        }
        this.activeIndex = parseInt(this.chose_index)
        if (this.activeIndex === 0) {
            this.logo = ''
            this.site_name = '登录系统后台'
            console.log(this.activeIndex)
        }
        this.approval_num = parseInt(localStorage.getItem('approval_num'))
    },
    methods: {
        menu_click(e) {
            console.log('menu_click')
            console.log(e)
        },
        menu_command(command) {
            console.log('command')
            console.log(command)
            if (command === 'b') {
                this.$router.push({path: '/chose_ahead', query: {batch: 'B'}})
            }else if (command === 'a') {
                this.$router.push({path: '/chose_ahead', query: {batch: 'A'}})
            } else if (command === 'ben') {
                this.$router.push('/chose_school')
            } else if (command === 'art') {
                this.$router.push('/chose_art')
            } else {
                this.$router.push('/chose_vocation')
            }
        },
        enter_home_page() {
            this.$router.push('/home_page')
        },
        sign_out() {
            let token = localStorage.getItem("agent_str")

            if (this.send_collection) {
                this.send_collection();
                localStorage.clear()
            } else {
                localStorage.clear()
            }

            if (token === undefined) {
                this.$router.push('/login')
            } else {
                this.$router.push('/login?agent=' + token)
            }
        }
    },
}
</script>

<style scoped>
* {
    font-family: 'Liberation Sans', 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
}

.selected {
    font-size: 16px;
    color: white;
}

.no_selected {
    color: white;
    font-size: 16px;
}

.selected_div {
    color: white;
    font-size: 15px;
    min-width: 150px;
    text-align: center;
    line-height: 40px;
    height: 40px;
    cursor: pointer;
    background-color: #b24b55;
}

.no_selected_div {
    color: white;
    font-size: 15px;
    min-width: 150px;
    text-align: center;
    line-height: 40px;
    height: 40px;
    cursor: pointer;
}

.header {
    width: 100%;
    height: 40px;
    text-align: center;
    margin: 0 auto 10px;
    position: fixed;
    z-index: 999999;
    top: 0;
    box-shadow: 0 5px 5px #999999;
}

.header_info {
    background-color: #ba0806;
    margin: 0 auto;
    width: 100%;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
}

.div_logo {
    display: flex;
    justify-content: flex-start;
    display: -webkit-flex;
    align-items: center;
}

.img_logo {
    height: 40px;
}

.name_logo {
    height: 40px;
    line-height: 40px;
    margin-left: 5px;
    color: white;
    margin-right: 100px;
    font-weight: bold;
    font-size: 20px;
    max-width: 400px;
    overflow: hidden;
}

.div_nav {
    font-size: 15px;
    min-width: 70%;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-around;
    align-items: center;
}

.div_nav div {
    min-width: 100px;
}

a {
    color: #666666;
    text-decoration: none;
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}

a:focus {
    text-decoration: none;
}
</style>
